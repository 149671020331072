<template>
	<div>
		<div v-if="loading">Loading order...</div>

		<div v-if="!valid">An error occurred while retrieving order info from server</div>

		<v-row v-else>
			<v-col sm="12">
				<div class="text-h4">Permit Order Complete</div>
				<p>Thank you for your order. Your confirmation and burning permit will be emailed to you shortly. You will also be able to download the permit from your customer dashboard.</p>
			</v-col>

			<v-col sm="12" md="6">
				<v-card>
					<v-card-title>Order Details</v-card-title>
					<v-divider />

					<!-- ORDER OVERVIEW -->
					<v-list two-line>

						<!-- order date -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Order Date</v-list-item-subtitle>
								<v-list-item-title>{{ order.ordered_on | dateFormat('MMM Do, YYYY') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- order ref # -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Order Reference #</v-list-item-subtitle>
								<v-list-item-title>{{ order.ref_num }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- permit being ordered -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Permit Ordered</v-list-item-subtitle>
								<v-list-item-title>{{ permit.permit_name }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ permit.price | currency }}</v-list-item-action>
						</v-list-item>

						<!-- discounts -->
						<v-list-item v-for="disc in discounts" :key="disc.id">
							<v-list-item-content>
								<v-list-item-title>{{ disc.discount_name }}</v-list-item-title>
								<v-list-item-subtitle>{{ disc.code_value }}</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>{{ -disc.deducted | currency }}</v-list-item-action>
						</v-list-item>

						<!-- subtotal -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Subtotal</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ order.subtotal | currency }}</v-list-item-action>
						</v-list-item>

						<!-- taxes -->
						<v-list-item v-for="tax in taxes" :key="tax.id">
							<v-list-item-content>
								<v-list-item-title>{{ tax.tax_name }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ tax.taxed_amount | currency }}</v-list-item-action>
						</v-list-item>
						

						<v-divider />

						<!-- total -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Total</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ order.total | currency }}</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col sm="12" md="6">
				<v-card>
					<v-card-title>Permit Details</v-card-title>
					<v-divider />

					<v-list two-line>
						<!-- permit name -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Permit</v-list-item-subtitle>
								<v-list-item-title>{{ permit.permit_name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- burning location -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Burning Location</v-list-item-subtitle>
								<v-list-item-title>{{ `${userPermit.street_number} ${userPermit.street_name}, ${userPermit.city}` }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- burning date range -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Allowed burning dates</v-list-item-subtitle>
								<v-list-item-title>{{ permit.from_date | dateFormat('MMM Do, YYYY') }} - {{ permit.to_date | dateFormat('MMM Do, YYYY') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<!-- burning time period -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-subtitle>Allowed burning time</v-list-item-subtitle>
								<v-list-item-title>{{ permit.from_time | to12Hour }} - {{ permit.to_time | to12Hour }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>

			<v-col sm="12">
				<div class="text-center">
					<v-btn rounded  large color="primary" to="/users"><v-icon left>arrow_back</v-icon> Customer Dashboard</v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import api from 'permits/frontend/api';
import filters from 'permits/shared/filters';


export default {
	mixins: [filters],

	props: {
		id: { type: [String, Number] }
	},

	data() {
		return {
			loading: true,
			valid: true,
			order: {},
			permit: {},
			lines: [],
			userPermit: {},
			taxes: [],
			discounts: [],
		}
	},

	methods: {
		async loadOrder()
		{
			this.loading = true;

			try
			{
				// fetch order data from server
				var result = await api.get(`/ordering/order_info/${this.id}`);

				// we successfully received the order
				this.valid = true;

				// assign to component state
				this.order = result.data.order;
				this.lines = result.data.lines;
				this.permit = result.data.permit;
				this.userPermit = result.data.user_permit;
				this.taxes = result.data.taxes;
				this.discounts = result.data.discounts;
			}
			catch(err)
			{
				this.valid = false;
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.loadOrder();
	}
}
</script>