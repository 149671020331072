
import router from 'permits/frontend/router';
import store from 'permits/frontend/store';

import OrderingStart from './comp/OrderingStart.vue';
import ApplicantInfo from './comp/ApplicantInfo.vue';
import CheckAddress from './comp/CheckAddress.vue';
import PropertyInfo from './comp/PropertyInfo.vue';
import SelectPermit from './comp/SelectPermit.vue';
import PermitTerms from './comp/PermitTerms.vue';
import Checkout from './comp/Checkout.vue';
import OrderComplete from './comp/OrderComplete.vue';

// function to check required completed steps
function checkCompletedSteps(steps)
{
	var completed = store.state.ordering.stepsCompleted;

	for(var step of steps)
		if (!completed[step])
			return false;

	return true;
}

// generate a navigation guard to check for required completed steps
function requireSteps(steps)
{
	return (to, from, next) => {
		if (checkCompletedSteps(steps))
			next();
		else
			next(false);
	}
}

router.addRoutes([
	{
		name: 'permit-ordering-start',
		path: '/ordering/start',
		component: OrderingStart,
	},
	{
		name: 'permit-ordering-applicant',
		path: '/ordering/applicant',
		component: ApplicantInfo,
	},
	{
		name: 'permit-ordering-check-addr',
		path: '/ordering/check_address',
		component: CheckAddress,
	},
	{
		name: 'permit-ordering-property',
		path: '/ordering/property',
		component: PropertyInfo,
		beforeEnter: requireSteps(['address-valid']),
	},
	{
		name: 'permit-ordering-permits',
		path: '/ordering/permits',
		component: SelectPermit,
		beforeEnter: requireSteps(['address-valid', 'property-entered']),
	},
	{
		name: 'permit-ordering-permit-terms',
		path: '/ordering/terms',
		component: PermitTerms,
		beforeEnter: requireSteps(['address-valid', 'property-entered', 'permit-selected']),
	},
	{
		name: 'permit-ordering-checkout',
		path: '/ordering/checkout',
		component: Checkout,
		beforeEnter: requireSteps(['address-valid', 'property-entered', 'permit-selected', 'permit-terms-agreed']),
	},
	{
		name: 'permit-ordering-complete',
		path: '/ordering/complete/:id',
		props: true,
		component: OrderComplete,
	},
]);