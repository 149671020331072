
//import 'sweetalert2/src/sweetalert2.scss'

import swal from 'sweetalert2';

export default {
	async show(text, title, type)
	{
		await swal.fire(title, text, type);
	},

	async confirm(text, title, type)
	{
		var result = await swal.fire({ text, title, icon: type || 'question', showCancelButton: true });
		return result.value ? true : false;
	}
}