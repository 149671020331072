<template>
	<div>
		<div class="pt-8">
			<user-profile-form class="mb-8" />

			<user-password-form />
		</div>
	</div>
</template>

<script>
import UserProfileForm from './UserProfileForm.vue';
import UserPasswordForm from './UserPasswordForm.vue';

export default {
	components: { UserProfileForm, UserPasswordForm },
}
</script>