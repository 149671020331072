<template>
	<v-card>
		<v-card-title>Manage Profile</v-card-title>
		<v-card-text>
			<v-form ref="form">
				<v-row>
					<v-col md="6">
						<v-text-field
							label="First Name"
							:rules="rules.first_name"
							v-model="user.first_name"
						/>
					</v-col>
					<v-col md="6">
						<v-text-field
							label="Last Name"
							:rules="rules.last_name"
							v-model="user.last_name"
						/>
					</v-col>
					<v-col sm="12">
						<v-text-field
							label="Street Address"
							:rules="rules.address1"
							v-model="user.address1"
						/>

						<v-text-field
							hide-label
							v-model="user.address2"
						/>
					</v-col>
					<v-col md="6">
						<v-text-field
							label="City"
							:rules="rules.city"
							v-model="user.city"
						/>
					</v-col>
					<v-col md="6">
						<v-text-field
							label="Postal Code"
							:rules="rules.postal_code"
							v-model="user.postal_code"
						/>
					</v-col>
					<v-col md="6">
						<v-text-field
							label="Phone #"
							:rules="rules.primary_phone"
							v-model="user.primary_phone"
						/>
					</v-col>
					<v-col md="6">
						<v-text-field
							label="Email"
							:rules="rules.email"
							v-model="user.email"
						/>
					</v-col>
					<v-col md="6">
						<v-select
							label="Contact Preference"
							:items="contactPrefs"
							:rules="rules.contact_pref"
							v-model="user.contact_pref"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<v-divider/>

		<v-card-actions>
			<v-btn text color="primary" :loading="saving" @click="updateProfile">Update My Profile</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';

import api from 'permits/frontend/api';
import alerts from 'permits/shared/alerts';
import validate from 'permits/shared/validators';

export default {
	data()
	{
		return {
			saving: false,
			user: {},

			contactPrefs: [
				{ text: '(select option)', value: '' },
				{ text: 'Primary Phone', value: 'primary_phone' },
				{ text: 'Cell Phone', value: 'cell_phone' },
				{ text: 'Email', value: 'email' },
			],

			rules: {
				first_name: [ v => !!v || 'Please enter your first name' ],
				last_name: [ v => !!v || 'Please enter your last name' ],
				address1: [ v => !!v || 'Please enter your street address' ],
				city: [ v => !!v || 'Please enter your city' ],
				postal_code: [ v => !!v || 'Please enter your postal code' ],
				primary_phone: [ v => !!v || 'Please enter your phone number' ],
				contact_pref: [ v => !!v || 'Please select a contact preference' ],
				email: [ 
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],
			}
		}
	},

	computed: {
		...mapState({
			currentUser: state => state.shell.currentUser
		}),
	},

	methods: {
		async updateProfile()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				var result = await api.post('/profile', this.user);

				//this.user = { ...result.data.user };
				//this.currentUser = { ...this.currentUser, ...this.user };

				await alerts.show('Profile updated successfully!', 'Success', 'success');
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	created()
	{
		this.user = { ...this.currentUser };
	}
}
</script>