<template>
	<v-card>
		<v-card-title>Discounts</v-card-title>

	</v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
	
}
</script>