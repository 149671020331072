<template>
	<v-app>
		<div class="shell-main">
			<div class="shell-top">
				<div class="top-content">
					<a href="https://www.afrsbc.ca/" target="_blank" class="logo">AFRSBC</a>
					<nav>
						<ul class="shell-navigation">
							<li v-if="currentUser"><a href="/users" class="nav-link">Dashboard</a></li>
							<li v-if="currentUser"><a href="/logout" class="nav-link">Log Out</a></li>
							<li><a href="https://www.afrsbc.ca/" target="_blank" class="nav-link">Back to AFRSBC Site</a></li>
						</ul>
					</nav>
				</div>
			</div>

			<v-content>
				<div class="shell-container">
					<router-view/>
				</div>
			</v-content>

			<div class="shell-copyright">
				<div class="copyright-content">
					{{ copyrightYear }} &copy; Abbotsford Fire Rescue Service
				</div>
			</div>
		</div>
	</v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			copyrightYear: (new Date()).getFullYear(),
		}
	},

	computed: {
		...mapState({
			currentUser: state => state.shell.currentUser,
		})
	}
}
</script>