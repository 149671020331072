
import { mapList } from 'permits/shared/util';

export default {
	setPermitList(state, list)
	{
		var { ids, objects: permits } = mapList(list);

		state.permits = permits;
		state.permitIds = ids;
	}
}