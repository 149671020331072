
export default {
	setCurrentUser(state, user)
	{
		state.currentUser = { ...user };
	},

	setTaxList(state, list)
	{
		state.taxList = list.map(tax => ({...tax}));
	},
}