<template>
	<div>
		<v-form ref="form" @submit.prevent="submit">
			<v-card>
				<v-card-title>
					<div class="text-title">Account Registration</div>
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col md="6">
							<v-text-field
								label="First Name"
								:value="applicant.first_name"
								:rules="rules.first_name"
								@input="updateField('first_name', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="Last Name"
								:value="applicant.last_name"
								:rules="rules.last_name"
								@input="updateField('last_name', $event)"
							/>
						</v-col>

						<v-col sm="12">
							<v-text-field
								label="Street Address"
								:value="applicant.address1"
								:rules="rules.address1"
								@input="updateField('address1', $event)"
							/>
							<v-text-field
								hide-label
								placeholder="Unit number"
								:value="applicant.address2"
								@input="updateField('address2', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="City"
								:value="applicant.city"
								:rules="rules.city"
								@input="updateField('city', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="Postal Code"
								:value="applicant.postal_code"
								:rules="rules.postal_code"
								@input="updateField('postal_code', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="Primary Phone"
								:value="applicant.primary_phone"
								:rules="rules.primary_phone"
								@input="updateField('primary_phone', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="Cell phone"
								:value="applicant.cell_phone"
								@input="updateField('cell_phone', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								label="Email Address"
								:value="applicant.email"
								:rules="rules.email"
								@input="updateField('email', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-select
								label="Contact Preference"
								:items="prefOptions"
								:value="applicant.contact_pref"
								:rules="rules.contact_pref"
								@input="updateField('contact_pref', $event)"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								type="password"
								label="Password"
								:rules="rules.password"
								v-model="password"
							/>
						</v-col>

						<v-col md="6">
							<v-text-field
								type="password"
								label="Confirm Password"
								:rules="rules.password_conf"
								v-model="passwordConf"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider/>
				
				<v-card-actions>
					<v-btn text type="submit" color="primary" :loading="loading">Create Account</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import api from 'permits/frontend/api';
import alerts from 'permits/shared/alerts';
import validate from 'permits/shared/validators';

export default {
	data()
	{
		return {
			loading: false,
			password: '',
			passwordConf: '',

			prefOptions: [
				{ value: 'primary_phone', text: 'Primary Phone' },
				{ value: 'cell_phone', text: 'Cell Phone' },
				{ value: 'email', text: 'Email' },
			],

			rules: {
				first_name: [ v => !!v.trim() || 'Please enter your first name' ],
				last_name: [ v => !!v.trim() || 'Please enter your last name' ],
				address1: [ v => !!v.trim() || 'Please enter your street address' ],
				city: [ v => !!v.trim() || 'Please enter your city' ],
				postal_code: [ v => !!v.trim() || 'Please enter your postal code' ],
				primary_phone: [ v => !!v.trim() || 'Please enter your primary phone number' ],
				contact_pref: [ v => !!v || 'Please select a contact preference' ],
				
				password: [ 
					v => !!v || 'Please enter a password',
					v => validate.password(v) || 'Passwords must be six characters or longer',
				],

				password_conf: [ v => v == this.password || 'Passwords do not match' ],

				email: [ 
					v => !!v.trim() || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],
			}
		}
	},

	computed: {
		...mapState({
			applicant: state => state.ordering.applicant,
		}),
	},

	methods: {
		...mapMutations('ordering', ['updateApplicantInfo']),

		updateField(field, value)
		{
			this.updateApplicantInfo({ [field]: value });
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var user, result;

				// new user data to send to server
				user = { 
					...this.applicant,
					password: this.password,
					password_confirmation: this.passwordConf,
				};

				// send user data to server and log them in
				result = await api.post('/users/create', { user });

				// commit user to shell state
				this.$store.commit('shell/setCurrentUser', user);

				// move to next step
				this.$router.push('/ordering/check_address');
			}
			catch(err)
			{
				console.error(err);
				alerts.show('An error occurred while verifying your email address, please try again', 'Error', 'error');
				return false;
			}
			finally
			{
				this.loading = false;
			}
		},
	},
}
</script>