
import router from 'permits/frontend/router';

import UsersMain from './comp/UsersMain.vue';
import UserProfile from './comp/UserProfile.vue';
import UserPermits from './comp/UserPermits.vue';

router.addRoutes([
	{
		name: 'users-home',
		path: '/users',
		component: UsersMain,
		children: [
			{
				path: 'user-permit-home-redirect',
				path: '',
				redirect: 'permits',
			},
			{
				path: 'user-permit-list',
				path: 'permits',
				component: UserPermits,
			},
			{
				path: 'users-profile',
				path: 'profile',
				component: UserProfile,
			}
		]
	}
])