
import defaultStates from './defaults';

export default {
	applicant: { ...defaultStates.applicant },
	property: { ...defaultStates.property },

	stepsCompleted: {},
	isNewCustomer: false,
	permits: {},
	permitIds: [],
	selectedPermit: null,
	discounts: {},
	discountIds: [],
}