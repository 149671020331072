
export default {
	applicant: {
		first_name: '',
		last_name: '',
		address1: '',
		address2: '',
		city: '',
		postal_code: '',
		primary_phone: '',
		cell_phone: '',
		email: '',
		contact_pref: '',
	},

	property: {
		street_number: '',
		street_name: '',
		city: 'Abbotsford',
		is_owner: true,
		owner_name: '',
		owner_address: '',
		owner_email: '',
		owner_phone: '',
		permission_file: null,
	},
}