<template>
	<div>
		<userbar />

		<router-view/>
	</div>
</template>

<script>

import requireUser from '../require-user';

import Userbar from './Userbar.vue';

export default {
	mixins: [requireUser],
	components: { Userbar }
}
</script>