<template>
	<v-container fluid grid-list-lg>
		<v-row wrap>
			<v-col sm="12" md="6">
				<checkout-details />
			</v-col>

			<v-col sm="12" md="6">

				<v-card>
					<v-card-title>Permit Checkout</v-card-title>

					<!-- ORDER OVERVIEW -->
					<v-list>
						<!-- permit being ordered -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>{{ selectedPermit.permit_name }}</v-list-item-title>
								<v-list-item-subtitle>Permit being requested</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>{{ selectedPermit.price | currency }}</v-list-item-action>
						</v-list-item>

						<!-- discounts -->
						<template v-if="addedDiscounts.length > 0">
							<v-divider/>

							<!-- list discounts -->
							<v-list-item v-for="disc in discountList" :key="`discount-${disc.id}`">
								<v-list-item-action class="mr-2">
									<v-btn icon @click="deleteDiscount(disc.discount_id)"><v-icon color="red">delete</v-icon></v-btn>
								</v-list-item-action>

								<v-list-item-content>
									<v-list-item-title>{{ disc.discount_name }}</v-list-item-title>
									<v-list-item-subtitle>{{ disc.code_value }}</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>{{ -disc.deduction | currency }}</v-list-item-action>
							</v-list-item>

							<!-- show subtotal after discounts -->
							<v-divider />
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Subtotal</v-list-item-title>
								</v-list-item-content>

								<v-list-item-action>{{ subtotal | currency }}</v-list-item-action>
							</v-list-item>
						</template>
						

						<!-- taxes -->
						<v-divider/>
						<v-list-item v-for="tax in taxes" :key="tax.id">
							<v-list-item-content>
								<v-list-item-title>{{ tax.tax_name }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ tax.amount | currency }}</v-list-item-action>
						</v-list-item>

						<v-divider />

						<!-- total -->
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Total</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>{{ total | currency }}</v-list-item-action>
						</v-list-item>
					</v-list>

					<v-divider />

					<v-card-text>
						<div class="text-overline">Discount</div>

						<v-text-field 
							persistent-hint
							class="mb-3"
							label="Add Promo Code"
							hint="If you have a discount code please add it here"
							v-model="promoCode"
						/>

						<v-btn outlined rounded small color="primary" :loading="addingDiscount" @click="addDiscountCode">
							<v-icon left>add</v-icon> Add Promo Code
						</v-btn>
					</v-card-text>

					<v-divider />

					<v-card-text v-if="total > 0">
						<div class="overline">Payment Information</div>

						<v-form ref="form">
							<v-row>
								<v-col sm="12" md="8">
									<v-text-field
										maxlength="16"
										label="Credit Card Number"
										append-icon="credit_card"
										v-model="ccNum" 
									/>
								</v-col>

								<v-col sm="12" md="4">
									<v-text-field 
										max-length="4"
										label="Security Code"
										append-icon="credit_card"
										v-model="ccSecurity"
									/>
								</v-col>
							</v-row>

							<v-row wrap>
								<v-col sm="12" md="4">
									<v-select 
										label="Expiry Month"
										:items="monthOptions"
										v-model="ccExpMonth" 
									/>
								</v-col>

								<v-col sm="12" md="4">
									<v-select 
										label="Expiry Year"
										:items="yearOptions"
										v-model="ccExpYear" 
									/>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>

					<v-divider />

					<v-card-actions>
						<v-btn text color="primary" :loading="processing" @click="submit">Submit Order</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import filters from 'permits/shared/filters';
import alerts from 'permits/shared/alerts';

import Discounts from './Discounts.vue';
import CheckoutDetails from './CheckoutDetails.vue';

export default {
	components: { Discounts, CheckoutDetails },
	mixins: [filters],

	data() 
	{
		return {
			processing: false,
			taxes: [],
			subtotal: 0,
			total: 0,
			ccNum: '',
			ccSecurity: '',
			ccExpMonth: '',
			ccExpYear: '',
			promoCode: '',
			addingDiscount: false,

			monthOptions: [
				{ text: 'Jan', value: '01' },
				{ text: 'Feb', value: '02' },
				{ text: 'Mar', value: '03' },
				{ text: 'Apr', value: '04' },
				{ text: 'May', value: '05' },
				{ text: 'Jun', value: '06' },
				{ text: 'Jul', value: '07' },
				{ text: 'Aug', value: '08' },
				{ text: 'Sep', value: '09' },
				{ text: 'Oct', value: '10' },
				{ text: 'Nov', value: '11' },
				{ text: 'Dec', value: '12' },
			]
		}
	},

	computed: {
		...mapGetters('ordering', ['addedDiscounts']),
		...mapState({
			selectedPermit: state => state.ordering.selectedPermit,
			taxList: state => state.shell.taxList,
		}),

		yearOptions()
		{
			var current = moment();
			var options = [];

			for(var i=0; i<10; i++)
			{
				let year = current.format('YYYY');
				
				options.push({ text: year, value: year.slice(2) });

				current.add(1, 'years');
			}

			return options;
		},

		// get list of discounts with the calculated deduction
		discountList()
		{
			var totalDeductions = 0;
			var result = [];

			for(let disc of this.addedDiscounts)
			{
				let item = { ...disc };

				if (item.deduct_type == 'flat')
					item.deduction = item.deduct_amount;
				else if (item.deduct_type == 'percent')
					item.deduction = this.selectedPermit.price * (item.deduct_amount / 100.0);

				result.push(item);
			}

			return result;
		}
	},

	methods: {
		...mapMutations('ordering', ['resetOrder', 'removeDiscount']),
		...mapActions('ordering', ['submitPermitOrder', 'getDiscount']),

		initOrder()
		{
			// get permit price
			var price = parseFloat(this.selectedPermit.price);

			// apply discounts
			if (this.addedDiscounts.length > 0)
			{
				for(var disc of this.discountList)
					price -= disc.deduction;

				if (price < 0)
					price = 0;
			}

			// subtotal is the price of the permit
			this.subtotal = price;
			this.total = this.subtotal;

			// calculate taxes and total
			this.taxes = [];
			for(var tax of this.taxList)
			{
				if (!this.selectedPermit.tax_exemptions.includes(tax.id))
				{
					let amount = tax.amount * this.subtotal;

					this.taxes.push({ ...tax, amount });
					this.total += amount;
				}
			}
		},

		async addDiscountCode()
		{
			this.addingDiscount = true;

			try
			{
				await this.getDiscount(this.promoCode);
				await alerts.show('Discount added successfully', 'Success', 'success');

				this.initOrder();

				this.promoCode = '';
			}
			finally
			{
				this.addingDiscount = false;
			}
		},

		async deleteDiscount(id)
		{
			if (await alerts.confirm('Are you sure you want to remove this discount?', 'Remove Discount', 'question'))
			{
				this.removeDiscount(id);
				this.initOrder();
			}
		},

		async submit()
		{
			this.processing = true;

			try
			{
				var params = {
					credit_card: {
						number: this.ccNum,
						security: this.ccSecurity,
						expiry: `${this.ccExpMonth}${this.ccExpYear}`,
					}
				};

				var result = await this.submitPermitOrder(params);

				// redirect user to order info
				this.$router.push(`/ordering/complete/${result.order_id}`);

				// clear order submission from state
				this.resetOrder();
			}
			finally
			{
				this.processing = false;
			}
		}
	},

	mounted()
	{
		this.initOrder();
	}
}
</script>