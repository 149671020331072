<template>
	<div class="pt-8">
		<!-- active permits -->
		<permit-list list-type="active" title="Active Permits" :loading="loading" />

		<!-- upcoming permits 
		<permit-list list-type="upcoming" title="Upcoming Permits" :loading="loading" />
		-->

		<!-- expired permits -->
		<permit-list list-type="expired" title="Expired Permits" :loading="loading" />
	</div>
</template>

<script>
import PermitList from './PermitList.vue';

export default {
	components: { PermitList },

	data()
	{
		return {
			loading: true,
		}
	},

	methods: {
		async refreshPermits()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('home/fetchPermitList');
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshPermits();
	}
}
</script>