
export function mapList()
{
	var data, arrayName, list;

	// function was called like mapList('arrayName', list)
	if (arguments.length == 2)
	{
		arrayName = arguments[0];
		list = arguments[1];
	}
	// function was called like mapList(list)
	else
	{
		arrayName = 'objects';
		list = arguments[0];
	}
	
	data = {
		ids: [],
		[arrayName]: {},
	};

	data.ids = list.map(obj => {
		data[arrayName][obj.id] = { ...obj };
		return obj.id;
	});

	return data;
}

export function slugify(text, noTrim)
{
	var slug = text;
	
	// trim whitespace
	if (!noTrim)
		slug = slug.replace(/^\s+|\s+$/g, '');
	
	// convert whitespace to underscores
	slug = slug.replace(/\s/g, '-');

	// replace repeated instances of - with a single -
	slug = slug.replace(/-+/g, '-');

	// remove non URL safe characters
	slug = slug.replace(/[^\w\d-_]/g, '');
	
	// make slug lower case and return
	return slug.toLowerCase();
}

/**
 * Performs a shallow copy of an object to a FormData instance. If you only want to copy certain fields of the object pass an array of strings as the 3rd parameter
 * @param {object} obj The object to convert to a FormData instance
 * @param {string} name Optional group name of object fields, e.g. 'foo' will assign each form data field as 'foo[<object field name>]' 
 * @param {array} keys Optional array of keys to filter by
 */
export function objectToFormData(obj, name, keys)
{
	var formData = new FormData();

	for(var field in obj)
	{
		// skip this field if it is not whitelisted
		if (keys && keys.indexOf(field) >= 0)
			continue;

		// skip if the value is not defined or null
		if (obj[field] == null)
			continue;

		let dataName = name ? `${name}[${field}]` : field;
		formData.append(dataName, obj[field]);
	}

	return formData;
}

