<template>
	<div>
		<v-form ref="form" @submit.prevent="submit">
			<v-card>
				<v-card-title>Property Details</v-card-title>

				<v-card-text>
					<v-text-field
						readonly
						label="Street Number"
						:value="property.street_number"
					/>

					<v-text-field
						readonly
						label="Street Name"
						:value="property.street_name"
					/>

					<v-text-field
						label="City"
						:value="property.city"
						:rules="rules.city"
						@input="updateField('city', $event)"
					/>

					<div class="text-subtitle-1">Are you a registered owner of this property?</div>
					<v-radio-group row hide-details v-model="isOwner" class="mb-4">
						<v-radio :value="true" label="Yes" />
						<v-radio :value="false" label="No" />
					</v-radio-group>

					<template v-if="!isOwner">
						<v-file-input
							v-model="ownerPermission"
							:rules="rules.permission_file"
							label="Please upload documentation demonstrating permission from the property owner"
						/>

						<v-text-field
							label="Property Owner Name"
							:value="property.owner_name"
							:rules="rules.owner_name"
							@input="updateField('owner_name', $event)"
						/>

						<v-text-field
							label="Property Owner Address"
							:value="property.owner_address"
							:rules="rules.owner_address"
							@input="updateField('owner_address', $event)"
						/>

						<v-text-field
							label="Property Owner Email"
							:value="property.owner_email"
							:rules="rules.owner_email"
							@input="updateField('owner_email', $event)"
						/>

						<v-text-field
							label="Property Owner Phone"
							:value="property.owner_phone"
							:rules="rules.owner_phone"
							@input="updateField('owner_phone', $event)"
						/>
					</template>
				</v-card-text>

				<v-divider/>
				
				<v-card-actions>
					<v-btn text type="submit" color="primary">Continue</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	data()
	{
		return {
			rules: {
				street_number: [ v => !!v || 'Please enter the property\'s street number' ],
				street_name: [ v => !!v || 'Please enter the property\'s street name' ],
				city: [ v => !!v || 'Please enter the property\'s city' ],
				permission_file: [ v => !!v || 'Please upload documentation demonstrating permission to burn' ],
				owner_name: [ v => !!v || 'Please enter the property owner\'s name' ],
				owner_address: [ v => !!v || 'Please enter the property owner\'s address' ],
				owner_email: [ v => !!v || 'Please enter the property owner\'s email address' ],
				owner_phone: [ v => !!v || 'Please enter the property owner\'s phone number' ],
			}
		}
	},

	computed: {
		...mapState({
			property: state => state.ordering.property,
		}),

		isOwner: {
			get() { 
				return this.property.is_owner;
			},

			set(value) {
				this.updateField('is_owner', value);
			}
		},

		ownerPermission: {
			get() {
				return this.property.permission_file;
			},
			set(value)
			{
				if (value == null || value.length == 0)
					this.updateField('permission_file', null);
				else
					this.updateField('permission_file', value);
			}
		}
	},

	watch: {
		isOwner(newVal)
		{
			// remove file from state if the user clicks Yes 
			if (newVal)
				this.updateField('permission_file', null);
		}
	},

	methods: {
		...mapMutations('ordering', ['updatePropertyInfo']),

		updateField(field, value)
		{
			this.updatePropertyInfo({ [field]: value });
		},

		submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.$store.commit('ordering/addCompletedStep', 'property-entered');
			this.$router.push('/ordering/permits');
		}
	}
}
</script>