import { render, staticRenderFns } from "./UserPermits.vue?vue&type=template&id=34283a48&"
import script from "./UserPermits.vue?vue&type=script&lang=js&"
export * from "./UserPermits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports