
import api from 'permits/frontend/api';

export default {
	async fetchPermitList({ commit })
	{
		var result = await api.get('/permits');
		commit('setPermitList', result.data.permits);
	},

	async submitPermitOrder({ state }, params)
	{
		var result, formData = new FormData();

		// add permit id to request
		formData.append('permit_id', state.selectedPermit.id);

		// add credit card parameters
		for(let key in params.credit_card)
			formData.append(`credit_card[${key}]`, params.credit_card[key]);

		// add property parameters
		for(let key in state.property)
			if (state.property[key] != null)
				formData.append(`user_permit[${key}]`, state.property[key]);

		// add used discount codes
		if (state.discountIds.length > 0)
		{
			let codeIds = state.discountIds.map(id => state.discounts[id].id).join(',');
			formData.append('discount_codes', codeIds);
		}
		
		// send order request to server
		result = await api.post('/ordering/submit', formData);

		return result.data;
	},

	async getDiscount({ state, commit }, code)
	{
		var params = { permit_id: state.selectedPermit.id };
		var result = await api.get(`/discounts/${code}`, { params });
		commit('addDiscount', result.data.discount_code);
	}
}