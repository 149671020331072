
import Vue from 'vue';

import { mapList } from 'permits/shared/util';
import defaultStates from './defaults';

export default {
	resetOrder(state)
	{
		state.applicant = { ...defaultStates.applicant };
		state.property = { ...defaultStates.property };
		state.isNewCustomer = false;
		state.stepsCompleted = {};
		state.permits = {};
		state.permitIds = [];
		state.discounts = {};
		state.discountIds = [];
		state.selectedPermit = null;
	},

	addCompletedStep(state, step)
	{
		state.stepsCompleted = { ...state.stepsCompleted, [step]: true };
	},

	setApplicantInfo(state, info)
	{
		state.applicant = { ...info };
	},

	updateApplicantInfo(state, info)
	{
		state.applicant = { ...state.applicant, ...info };
	},

	updatePropertyInfo(state, info)
	{
		state.property = { ...state.property, ...info };
	},

	setPermitList(state, list)
	{
		var { objects, ids } = mapList(list);

		state.permits = objects;
		state.permitIds = ids;
	},

	setSelectedPermit(state, permit)
	{
		if (permit == null)
			state.selectedPermit = null;
		else
			state.selectedPermit = { ...permit };
	},

	addDiscount(state, discount)
	{
		if (!state.discounts[discount.discount_id])
		{
			Vue.set(state.discounts, discount.discount_id, { ...discount });
			state.discountIds.push(discount.discount_id);
		}
	},

	removeDiscount(state, id)
	{
		var pos = state.discountIds.indexOf(id);

		if (state.discounts[id])
			Vue.delete(state.discounts, id);

		if (pos >= 0)
			state.discountIds.splice(pos, 1);
	}
}