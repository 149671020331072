
import 'vuetify/dist/vuetify.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
//import '@sweetalert2/themes/material-ui/material-ui.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';

// configure routing/app store
import router from 'permits/frontend/router';
import store from 'permits/frontend/store';

// import shell component
import FrontendMain from 'permits/frontend/shell/comp/FrontendMain.vue';

// import global components
//import 'permits/admin/comp';

// import main sections
import 'permits/frontend/ordering';
import 'permits/frontend/users';

// configure vuetify
const themeColors = {
	primary: '#d9251c',
	accent: '#d7736e',
	secondary: "#b20000",
};

Vue.use(Vuetify);
const vuetify = new Vuetify({
	theme: {
		themes: {
			dark: themeColors,
			light: themeColors,
		},
		dark: false,
	}
});


// initialize shell data
if (window._initData.currentUser)
	store.commit('shell/setCurrentUser', window._initData.currentUser);

store.commit('shell/setTaxList', window._initData.taxes);

// initialize main vue instance
const app = new Vue({
	el: '#app-root',
	vuetify,
	router,
	store,

	render(h) {
		return h(FrontendMain);
	}
});