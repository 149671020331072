var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-8",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.permits,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","rounded":"","outlined":"","color":"info","elevation":"0"},on:{"click":function($event){return _vm.gotoPermitPDF(item.id)}}},[_c('v-icon',{attrs:{"icon-left":"","small":""}},[_vm._v("picture_as_pdf")]),_vm._v(" Permit")],1)]}},{key:"item.issued_on",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(_vm._f("dateFormat")(item.issued_on,'MMM Do, YYYY'))+"\n\t\t")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(item.street_number)+" "+_vm._s(item.street_name)+", "+_vm._s(item.city)+"\n\t\t")]}},{key:"item.date_range",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(_vm._f("dateFormat")(item.from_date,'MMM Do, YYYY'))+" - "+_vm._s(_vm._f("dateFormat")(item.to_date,'MMM Do, YYYY'))+"\n\t\t")]}},{key:"item.time_window",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(_vm._f("to12Hour")(item.from_time))+" - "+_vm._s(_vm._f("to12Hour")(item.to_time))+"\n\t\t")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }