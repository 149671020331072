<template>
	<div>
		<p><strong>Open Burning Address Lookup</strong></p>

		<p>
			Enter your street number to find your address below. 
			<span style="font-weight: bold; font-size: 1.2em;">If your street does not appear your address is not eligible for open burning.</span>
		</p>

		<v-form ref="form" @submit.prevent="checkAddress">
			<v-card>
				<v-card-text>
					<v-text-field
						label="House Number"
						:disabled="checking"
						:rules="rules.houseNumber"
						v-model="houseNumber"
					/>

					<v-select 
						label="Street Name"
						:disabled="checking"
						:loading="loading"
						:items="streetOptions"
						:rules="rules.streetName"
						v-model="streetName"
					/>
				</v-card-text>

				<v-divider />

				<v-card-actions>
					<v-btn text type="submit" :loading="checking" color="primary">Check Address</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</div>
</template>

<script>
import alerts from 'permits/shared/alerts';
import api from 'permits/frontend/api';

export default {
	data()
	{
		return {
			houseNumber: '',
			streetName: '',
			streetOptions: [],
			loading: false,
			checking: false,

			rules: {
				houseNumber: [ v => !!v.trim() || 'Please enter a house number' ],
				streetName: [ v => !!v.trim() || 'Please enter a street name' ],
			}
		}
	},

	watch: {
		houseNumber(newVal, oldVal)
		{
			if (newVal != oldVal)
				this.refreshStreetOptions();
		}
	},

	methods: {
		async refreshStreetOptions()
		{
			if (this.houseNumber.trim() == '')
			{
				this.streetOptions = [];
				return;
			}

			if (this.loading)
				return;

			this.loading = true;
			this.streetName = '';

			try
			{
				var params = { number: this.houseNumber.trim() };
				var result = await api.get('/properties/suggestions', { params });

				this.streetOptions = result.data.streets;
			}
			finally
			{
				this.loading = false;
			}
		},

		async checkAddress()
		{
			if (!this.$refs.form.validate())
				return;

			this.checking

			try
			{
				var params = { street_name: this.streetName, street_number: this.houseNumber };
				var result = await api.get('/properties/find', { params });

				if (result.data.ok)
				{
					await alerts.show('Property validated, continuing to permit selection', 'Success', 'success');

					this.$store.commit('ordering/updatePropertyInfo', {
						street_number: this.houseNumber,
						street_name: this.streetName,
					});

					this.$store.commit('ordering/addCompletedStep', 'address-valid');
					this.$router.push('/ordering/property');
				}
				else
				{
					await alerts.show('Your property was not found or is not eligible for open burning', 'We\'re Sorry', 'warning');
				}
			}
			finally
			{
				this.checking = false;
			}
		}
	}
}
</script>