
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import shell from 'permits/frontend/shell/store';
import ordering from 'permits/frontend/ordering/store';
import home from 'permits/frontend/users/store';

// vuex persistence plugin to persist ordering process state
const vuexSession = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['ordering'],
});

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		shell, ordering, home,
	},
	plugins: [vuexSession.plugin],
});

