
import store from 'permits/frontend/store';

export default {
	beforeRouteEnter(to, from, next)
	{
		if (store.state.shell.currentUser == null)
			document.location = '/login';
		else
			next();
	},

	beforeRouteUpdate(to, from, next)
	{
		if (store.state.shell.currentUser == null)
			document.location = '/login';
		else
			next();
	}
}