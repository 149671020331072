<template>
	<div>
		<v-card :loading="loading">
			<v-card-title>Select a Permit</v-card-title>

			<v-card v-if="loading">Loading...</v-card>

			<template v-else-if="permitList.length == 0">
				<v-divider/>

				<v-card-text>
					We're sorry, but there are no permits currently available.
				</v-card-text>
			</template>

			<template v-else>
				<v-divider />

				<v-list two-line>
					<v-list-item v-for="p in permitList" :key="p.id" @click="selectedId = p.id" :ripple="false">
						<v-list-item-action>
							<v-icon color="primary">{{ p.id == selectedId ? 'radio_button_checked' : 'radio_button_unchecked' }}</v-icon>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>{{ p.permit_name }}</v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>{{ p.price | currency }}</v-list-item-action>
					</v-list-item>
				</v-list>

				<v-divider />

				<v-card-actions>
					<v-btn text color="primary" @click="submit">Continue</v-btn>
				</v-card-actions>
			</template>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import alerts from 'permits/shared/alerts';
import filters from 'permits/shared/filters';

export default {
	mixins: [filters],

	data() {
		return {
			loading: true,
			selectedId: null,
		}
	},

	computed: {
		...mapGetters('ordering', ['permitList']),
		...mapState({
			permits: state => state.ordering.permits,
		})
	},

	methods: {
		...mapMutations('ordering', ['setSelectedPermit']),
		...mapActions('ordering', ['fetchPermitList']),

		async refreshList()
		{
			this.loading = true;

			try
			{
				this.fetchPermitList();
			}
			finally
			{
				this.loading = false;
			}
		},

		async submit()
		{
			if (!this.selectedId)
			{
				alerts.show('You must select a permit before continuing', 'No Permit Selected', 'warning');
				return;
			}
			else if (this.permits[this.selectedId])
			{
				// set selected permit in state
				this.setSelectedPermit(this.permits[this.selectedId]);

				// redirect to permit terms listing
				this.$store.commit('ordering/addCompletedStep', 'permit-selected');
				this.$router.push('/ordering/terms');
			}
		}
	},

	mounted()
	{
		this.refreshList();
	}
}
</script>