<template>
	<div>
		<v-card>
			<v-card-title>Permit Application Details</v-card-title>

			<v-card-text>
				<div class="overline">Property Info</div>
				<v-row>
					<v-col sm="12">
						<v-text-field dense readonly label="Street Name/Number" :value="`${property.street_number} ${property.street_name}`" />
					</v-col>
					<v-col sm="12">
						<v-text-field dense readonly label="City" :value="property.city" />
					</v-col>
					<v-col sm="12">
						<v-text-field dense readonly label="Is Property Owner?" :value="`${property.is_owner ? 'Yes' : 'No'}`" />
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider/>

			<v-card-text>
				<div class="overline">Applicant Info</div>
				<v-row wrap>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="First Name" :value="applicant.first_name" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Last Name" :value="applicant.last_name" />
					</v-col>
					<v-col sm="12">
						<v-textarea no-resize dense readonly :rows="2" label="Street Address" :value="`${applicant.address1}\n${applicant.address2}`"/>
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="City" :value="applicant.city" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Postal Code" :value="applicant.postal_code" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Primary Phone" :value="applicant.primary_phone" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Cell Phone" :value="applicant.cell_phone" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Email Address" :value="applicant.email" />
					</v-col>
					<v-col sm="12" md="6">
						<v-text-field dense readonly label="Contact Preference" :value="$options.contactPrefLabels[applicant.contact_pref] || 'N/A'" />
					</v-col>
				</v-row>

			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { contactPrefLabels } from 'permits/shared/labels';

export default {
	contactPrefLabels,

	data()
	{
		return {
		}
	},

	computed: {
		...mapState({
			applicant: state => state.ordering.applicant,
			property: state => state.ordering.property,
		})
	}
}
</script>