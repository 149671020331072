<template>
	<v-card>
		<v-card-title>Update Password</v-card-title>
		<v-card-text>
			<v-form ref="form">
				<v-text-field
					type="password"
					label="New Password"
					:rules="rules.password"
					v-model="form.password"
				/>

				<v-text-field
					type="password"
					label="Confirm New Password"
					:rules="rules.password_confirmation"
					v-model="form.password_confirmation"
				/>
			</v-form>
		</v-card-text>

		<v-divider/>

		<v-card-actions>
			<v-btn text color="primary" :loading="saving" @click="save">Update Password</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import api from 'permits/frontend/api';
import validate from 'permits/shared/validators';
import alerts from 'permits/shared/alerts';

export default {
	data()
	{
		return {
			saving: false,

			form: {
				password: '',
				password_confirmation: '',
			},

			rules: {
				password: [
					v => !!v || 'Please enter your new password',
					v => validate.password(v) || 'Passwords must be six characters or longer',
				],

				password_confirmation: [
					v => !!v || 'Please re-enter your new password',
					v => v == this.form.password || 'Passwords do not match!',
				]
			}
		}
	},

	methods: {
		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				await api.post('/profile/password', { passwords: this.form });
				await alerts.show('Password updated successfully', 'Success', 'success');

				this.form = { password: '', password_confirmation: '' };
				this.$refs.form.resetValidation();
			}
			finally
			{
				this.saving = false;
			}
		}
	}
}
</script>