<template>
	<div>
		<v-card>
			<v-card-title>Permit Terms and Conditions</v-card-title>
			
			<v-card-text>
				<span style="font-weight: bold; font-size: 1.2em;">You must agree to all terms and conditions below</span>
			</v-card-text>

			<v-list>
				<v-list-item v-for="term in selectedPermit.terms" :key="term.id" @click="toggleTerm(!isSelected(term.id), term.id)">
					<v-list-item-action>
						<v-simple-checkbox color="primary" :value="isSelected(term.id)" @input="toggleTerm($event, term.id)" />
					</v-list-item-action>

					<v-list-item-content>
						<v-list-item-title class="text-wrap">{{ term.term_desc }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider />

			<v-card-actions>
				<v-btn text color="primary" :disabled="!canContinue" @click="gotoCheckout">Checkout</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import alerts from 'permits/shared/alerts';

export default {

	data() 
	{
		return {
			agreed: [],
		}
	},

	computed: {
		...mapState({
			selectedPermit: state => state.ordering.selectedPermit
		}),

		// return true if all of the terms are selected
		canContinue()
		{
			// go through all of the permit terms, as soon as one of them is not present in this.agreed return false
			for(var term of this.selectedPermit.terms)
				if (!this.isSelected(term.id))
					return false;

			// if we hit this point then all terms are selected
			return true;
		}
	},

	methods: {
		isSelected(id)
		{
			return this.agreed.indexOf(id) >= 0;
		},

		toggleTerm(flag, id)
		{
			var pos = this.agreed.indexOf(id);
		
			// toggle this term as agreed
			if (flag && pos == -1)
				this.agreed.push(id);
			else if (!flag && pos >= 0)
				this.agreed.splice(pos, 1);
		},

		gotoCheckout()
		{
			if (this.canContinue)
			{
				this.$store.commit('ordering/addCompletedStep', 'permit-terms-agreed');
				this.$router.push('/ordering/checkout');
			}
		}
	}
}
</script>