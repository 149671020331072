
import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	base: '/users/',

	routes: [
		{
			name: 'frontend-home-redirect',
			path: '/',
			redirect: '/users',
			beforeEnter(to, from, next) {
				if (store.state.shell.currentUser == null)
					document.location = '/login';
				else
					next();
			}
		}
	]
});