<template>
	<v-card :loading="loading" class="mb-8">
		<v-card-title>{{ title }}</v-card-title>

		<v-data-table
			hide-default-footer
			:items="permits"
			:headers="headers">

			<template v-slot:item.actions="{ item }">
				<v-btn small rounded outlined color="info" elevation="0" @click="gotoPermitPDF(item.id)"><v-icon icon-left small>picture_as_pdf</v-icon> Permit</v-btn>
			</template>

			<template v-slot:item.issued_on="{ item }">
				{{ item.issued_on | dateFormat('MMM Do, YYYY') }}
			</template>

			<template v-slot:item.location="{ item }">
				{{ item.street_number }} {{ item.street_name }}, {{ item.city }}
			</template>

			<template v-slot:item.date_range="{ item }">
				{{ item.from_date | dateFormat('MMM Do, YYYY') }} - {{ item.to_date | dateFormat('MMM Do, YYYY') }}
			</template>

			<template v-slot:item.time_window="{ item }">
				{{ item.from_time | to12Hour }} - {{ item.to_time | to12Hour }}
			</template>

		</v-data-table>			
	</v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import filters from 'permits/shared/filters';

const today = moment();

export default {
	mixins: [filters],

	props: {
		title: { type: String },
		loading: { type: Boolean, default: false },
		listType: { type: String },
	},

	data() 
	{
		return {
			headers: [
				{ text: '', sortable: false, value: 'actions', width: 150 },
				{ text: 'ID#', sortable: false, value: 'id' },
				{ text: 'Issued On', sortable: false, value: 'issued_on' },
				{ text: 'Location', sortable: false, value: 'location' },
				{ text: 'Date Range', sortable: false, value: 'date_range' },
				{ text: 'Time Window', sortable: false, value: 'time_window' },
			]
		}
	},

	computed: {
		...mapGetters('home', ['permitList']),

		// return list of permits to show
		permits()
		{
			return this.permitList.filter(p => {
				var from = moment(p.from_date, 'YYYY-MM-DD');
				var to = moment(p.to_date, 'YYYY-MM-DD');

				if (this.listType == 'active')
					return today.isBetween(from, to);
				else if (this.listType == 'upcoming')
					return from.isAfter(today);
				else if (this.listType == 'expired')
					return to.isBefore(today);
				else
					return false;
			});
		}
	},

	methods: {
		gotoPermitPDF(id)
		{
			var url = `/users/api/user_permits/${id}/pdf`;
			window.open(url, '_blank');
		}
	},
}
</script>