<template>
	<div>
		<template v-if="loading">
			Loading...
		</template>

		<template v-else-if="error || content.length == 0">
			Unable to load terms and conditions
		</template>

		<template v-else>

			<div v-for="item in content" :key="item.id">
				<div class="text-h4 text-center mb-4">{{ item.title }}</div>
				<div class="content-item-body" v-html="item.content"/>
			</div>

			<v-checkbox v-model="agreed" label="I agree that by clicking on the &quot;Agreement&quot; button and &quot;Submit&quot; button of this application I am effectively signing my application." />

			<div class="text-center">
				<v-btn large color="primary" @click="nextStep">Continue</v-btn>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import alerts from 'permits/shared/alerts';
import api from 'permits/frontend/api';

//import Terms from './Terms.vue';

export default {
	//components: { Terms },

	data()
	{
		return {
			loading: true,
			error: false,
			agreed: false,
			content: [],
		}
	},

	computed: {
		...mapState({
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		...mapMutations('ordering', ['resetOrder', 'setApplicantInfo']),

		async loadContent()
		{
			this.loading = true;
			this.error = false;

			try
			{
				var result = await api.get('/content_items/terms');
				
				this.content = result.data.items;
			}
			catch(err)
			{
				this.error = true;
			}
			finally
			{
				this.loading = false;
			}
		},

		nextStep()
		{
			if (this.agreed)
			{
				// if a user is already logged in copy their details to the applicant state for convenience
				if (this.currentUser)
				{
					var keys = ['first_name', 'last_name', 'address1', 'address2', 'city', 'postal_code', 'primary_phone', 'cell_phone', 'email', 'contact_pref'];
					var appl = {};
					
					for(var key of keys)
						appl[key] = this.currentUser[key];
					
					this.setApplicantInfo(appl);

					// redirect to address verification
					this.$router.push('/ordering/check_address');
				}
				// otherwise redirect to new applicant form
				else
				{
					this.$router.push('/ordering/applicant');
				}
			}
			else
			{
				alerts.show('You must agree to the terms and conditions above before continuing', 'Notice', 'warning');
			}
		}
	},

	mounted()
	{
		this.loadContent();

		// reset order state
		this.resetOrder();
	}
}
</script>